<template>
  <div class="wrap">
    <div class="successContent content_area">
      <img
        src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteUseing/atfwebsite/success.png"
        alt
        srcset
      />
      <p v-if="apply_type === 4" class="thanks">{{ $t("enrollSuccess") }}</p>
      <p class="thanks">{{ $t("my_registerSuccess_p1") }}</p>
      <p v-if="apply_type !== 4">{{ $t("my_registerSuccess_p2") }}</p>
      <div class="buttonBox tc">
        <el-button type="primary" @click="gotoCenter" size="small" plain>{{
          $t("backHome")
        }}</el-button>
        <!--        <el-button-->
        <!--            size="small"-->
        <!--            class="fr"-->
        <!--            @click="gotoProgam"-->
        <!--            plain-->
        <!--        >{{$t('i18n.thankEnrollSuccess3')}}</el-button>-->
      </div>
    </div>
  </div>
</template>

<script>
import { _decode } from "@/basePlugins/base64";
export default {
  name: "success",
  data() {
    return {
      apply_type: null,
    };
  },
  created() {
    let queryParams = _decode(this.$route.query.parameter);

    this.apply_type = queryParams.apply_type;
  },
  methods: {
    gotoCenter() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped lang="less">
.wrap {
  padding: 24px 0;
  .successContent {
    background: #fff;
    padding: 200px 420px;
    text-align: center;
    .thanks {
      font-weight: 900;
      margin: 10px 0;
      font-size: 16px;
    }
    .buttonBox {
      width: 337px;
      margin: 10px auto;
    }
  }
}
</style>
